import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
  Badge,
  CheckboxGroup,
  Checkbox,
  Stack,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import client from "../../utils/client";
import { useEffect, useState } from "react";
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const split = process.env.REACT_APP_S3_BUCKET.split("/");
  const [permissions, setPermissions] = useState(user.type || []);
  const toast = useToast();
  const navigate = useNavigate();

  const handlePermissionsChange = (newPermissions) => {
    setIsLoading(true); // Inicia o loading
    setPermissions(newPermissions);
  };

  useEffect(() => {
    const updatePermissions = async () => {
      try {
        // Faz a requisição para atualizar as permissões do usuário
        await client.post(`/users/promote/${user.userId}`, {
          type: permissions,
        });
      } catch (error) {
        console.error("Erro ao atualizar permissões:", error);
        toast({
          title: "Erro ao atualizar permissões.",
          description: "Houve um problema ao tentar atualizar as permissões.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false); // Termina o loading após a requisição
      }
    };

    if (isLoading) {
      updatePermissions();
    }
  }, [permissions, isLoading, toast, user.userId]);

  const userTypes = permissions.map((el) =>
    el === process.env.REACT_APP_ADMINISTRATOR
      ? "Administrador"
      : el === process.env.REACT_APP_FELLOWMEMBER
      ? "Acadêmico"
      : el === process.env.REACT_APP_CORRESPONDENT
      ? "Correspondente"
      : el === process.env.REACT_APP_COLABORATOR
      ? "Colaborador"
      : el === process.env.REACT_APP_LIBRARY
      ? "Bibliotecário"
      : el === process.env.REACT_APP_GUEST
      ? "Usuário comum"
      : "Erro"
  );

  //   const handleDelete = async () => {
  //     setIsLoading(true);
  //     try {
  //       await client.delete(`/users/${user.userId}`);
  //       toast({
  //         title: "Usuário excluído",
  //         description: `O usuário ${user.name} foi excluído com sucesso.`,
  //         status: "success",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //       // Você pode adicionar lógica para redirecionar ou atualizar a lista de usuários aqui
  //       navigate("/gerenciamento_usuarios");
  //     } catch (error) {
  //       console.error("Erro ao excluir usuário:", error);
  //       toast({
  //         title: "Erro ao excluir usuário.",
  //         description: "Houve um problema ao tentar excluir o usuário.",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={"column"} gap={1.5}>
              <Flex gap={2}>
                <Text fontWeight={700}>Nome: </Text>
                <Text>
                  {user?.name} {user?.surname}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Tipo: </Text>
                <Text>
                  {userTypes.map((el) => (
                    <Badge mx={1} colorScheme="linkedin">
                      {el}
                    </Badge>
                  ))}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Email: </Text>
                <Text>{user?.email}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Telefone: </Text>
                <Text>{user?.phoneNumber}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Oriente: </Text>
                <Text>
                  {user?.city} - {user?.state}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Endereço: </Text>
                <Text>{user?.address}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Trabalho: </Text>
                <Text>{user?.job}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Aniversário: </Text>
                <Text>{user?.birthdate}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Maçom ativo: </Text>
                <Text>
                  {user?.isActiveMason === "true" ? "Ativo" : "Adormecido"}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Loja: </Text>
                <Text>{user?.lodge}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Potência: </Text>
                <Text>{user?.obedience}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Graus: </Text>
                <Text>{user?.allGrades}</Text>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                <Image
                  src={
                    user?.photo
                      ? `https://${split[0]}.s3.amazonaws.com/${split[1]}/${user.photo}`
                      : "/assets/defUser.webp"
                  }
                  borderRadius={"100%"}
                  w={"150px"}
                  h={"150px"}
                  objectFit={"cover"}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Tr>
        <Td>
          {user?.name} {user?.surname}
        </Td>
        <Td>{user?.email}</Td>
        <Td>{user?.phoneNumber}</Td>
        <Td>
          {isLoading ? (
            <Flex align="center" justify="center" my={4}>
              <Spinner size="lg" color="blue.500" />
              <Text ml={3}>Atualizando permissões...</Text>
            </Flex>
          ) : (
            <CheckboxGroup
              colorScheme="linkedin"
              value={permissions}
              onChange={handlePermissionsChange} // Chamado sempre que uma permissão é marcada ou desmarcada
            >
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                <Checkbox
                  size={"sm"}
                  value={process.env.REACT_APP_ADMINISTRATOR}
                >
                  Administrador
                </Checkbox>

                <Checkbox
                  size={"sm"}
                  value={process.env.REACT_APP_FELLOWMEMBER}
                >
                  Acadêmico
                </Checkbox>
                <Checkbox size={"sm"} value={process.env.REACT_APP_GUEST}>
                  Comum
                </Checkbox>
                <Checkbox size={"sm"} value={process.env.REACT_APP_COLABORATOR}>
                  Colaborador
                </Checkbox>
                <Checkbox
                  size={"sm"}
                  value={process.env.REACT_APP_CORRESPONDENT}
                >
                  Correspondente
                </Checkbox>
                <Checkbox size={"sm"} value={process.env.REACT_APP_LIBRARY}>
                  Bibliotecário
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          )}
        </Td>
        <Td>
          <Flex gap={3}>
            <Tooltip label={"Detalhar usuário " + user.name}>
              <IconButton
                size={"sm"}
                colorScheme="blue"
                aria-label="Detalhes"
                icon={<SearchIcon />}
                onClick={onOpen}
              />
            </Tooltip>
            {/* <Tooltip label={"Excluir usuário " + user.name}>
              <IconButton
                size={"sm"}
                colorScheme="red"
                aria-label="Excluir Usuário"
                icon={<DeleteIcon />}
                onClick={handleDelete}
              />
            </Tooltip> */}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
export default UserCard;
