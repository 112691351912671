import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useNavigate } from "react-router-dom";
import NewsItem from "../components/NewsItem";
import NotAuthorized from "./NotAuthorized";
import { useAuth } from "../context/AuthContext";

const AllNews = () => {
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const fetchNews = async (page) => {
    setLoading(true);
    try {
      const response = await client.get(`/news?page=${page}`);
      setNewsItems(response.data.newsItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch news:", error);
    }
  };

  useEffect(() => {
    fetchNews(currentPage);
  }, [currentPage]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDelete = async (newsId) => {
    await client.delete(`/news/${newsId}`);
    setNewsItems(newsItems.filter((news) => news.newsId !== newsId));
  };

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_COLABORATOR) ? (
        <Layout>
          <Content>
            <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
              <Flex
                display={["flex", "flex", "none"]}
                px={2}
                w={"100%"}
                zIndex={"80"}
              >
                {/* <Carousel items={ads} maxH={["30vh", "30vh", "40vh"]} /> */}
              </Flex>

              <Heading
                px={[2, 6]}
                pt={[2]}
                variant={"outline"}
                colorScheme="yellow"
                textDecor={"underline"}
                alignSelf={"flex-start"}
                textDecorationColor={"#01acec"}
              >
                Gerenciar Notícias
              </Heading>
              <Button
                colorScheme="green"
                onClick={() => navigate("/adicionar_noticia")}
              >
                Adicionar Notícia
              </Button>

              <Flex w={"100%"}>
                <Box p={5} w={"100%"}>
                  <TableContainer>
                    <Table variant="striped" size={"sm"}>
                      <Thead>
                        <Tr>
                          <Th>Título</Th>
                          <Th>Autor</Th>
                          <Th>Data de Criação</Th>
                          <Th>Qtd Comentários</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loading
                          ? // Skeleton enquanto os dados estão sendo carregados
                            [...Array(3)].map((_, index) => (
                              <Tr key={index}>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                              </Tr>
                            ))
                          : // Renderiza os dados quando disponíveis
                            newsItems.map((news) => (
                              <NewsItem
                                key={news.newsId}
                                onDelete={handleDelete}
                                news={news}
                              />
                            ))}
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {/* Controles de Paginação */}
                  <Flex
                    mt={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      onClick={goToPreviousPage}
                      isDisabled={currentPage === 1}
                      colorScheme="blue"
                    >
                      Anterior
                    </Button>

                    <Text>
                      Página {currentPage} de {totalPages}
                    </Text>

                    <Button
                      onClick={goToNextPage}
                      isDisabled={currentPage === totalPages}
                      colorScheme="blue"
                    >
                      Próxima
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};
export default AllNews;
