import {
  Button,
  Flex,
  Heading,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import client from "../utils/client";
import AdvertiserCard from "../components/AdvertiserCard";
import { useNavigate } from "react-router-dom";

const AllAdvertisers = () => {
  const [allAdvertisers, setAllAdvertisers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredAdvertisers, setFilteredAdvertisers] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      const response = await client.get("/advertisers");
      if (response?.data) {
        const ordered = response.data.sort((a, b) => {
          if (a.advertiserName < b.advertiserName) {
            return -1;
          }
          if (a.advertiserName > b.advertiserName) {
            return 1;
          }
          return 0;
        });
        setAllAdvertisers(ordered);
        setFilteredAdvertisers(ordered);
      }
    };
    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilteredAdvertisers(
      allAdvertisers.filter((el) =>
        el.advertiserName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ? (
        <Layout>
          <Content>
            <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
              <Heading
                px={[2, 6]}
                pt={[2]}
                variant={"outline"}
                colorScheme="yellow"
                textDecor={"underline"}
                alignSelf={"flex-start"}
                textDecorationColor={"#01acec"}
              >
                Gerenciar Anunciantes
              </Heading>
              <Button
                colorScheme="green"
                onClick={() => navigate("/adicionar_anuncio")}
              >
                Adicionar Anunciante
              </Button>

              <Flex alignItems={"center"} flexDir={"column"} w={"100%"}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Flex
                    w={"100%"}
                    gap={5}
                    wrap={"wrap"}
                    justifyContent={"center"}
                    mt={5}
                  >
                    <Input
                      type="text"
                      placeholder="Pesquisar"
                      onChange={handleSearch}
                    />
                    <TableContainer w={"100%"}>
                      <Table
                        variant="striped"
                        size={"sm"}
                        colorScheme="linkedin"
                      >
                        <Thead>
                          <Tr>
                            <Th>Nome</Th>
                            <Th>Tipo</Th>
                            <Th>Data de Expiração</Th>
                            <Th>URL de Destino</Th>
                            <Th>Ações</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredAdvertisers[0]
                            ? filteredAdvertisers.map((advertiser) => (
                                <AdvertiserCard
                                  key={advertiser.advertiserId}
                                  advertiser={advertiser}
                                />
                              ))
                            : allAdvertisers[0] &&
                              allAdvertisers.map((advertiser) => (
                                <AdvertiserCard
                                  key={advertiser.advertiserId}
                                  advertiser={advertiser}
                                />
                              ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <>Não autorizado</>
      )}
    </>
  );
};
export default AllAdvertisers;
