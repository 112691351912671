import { Badge, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import AdsMobile from "../components/AdsMobile";

import AdsDesktop from "../components/AdsDesktop";
import Blogs from "../components/Blogs";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { Link } from "react-router-dom";

const Blog = () => {
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]); // State to store blogs of the selected category
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store selected category

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/categories`);
      response.data && setCategories(response.data);
    };
    request();
  }, []);

  const fetchBlogsByCategory = async (categoryId) => {
    try {
      const response = await client.get(`/blogs/category/${categoryId}`);
      setBlogs(response.data); // Update the blogs state with the response
    } catch (error) {
      console.error("Failed to fetch blogs for the selected category:", error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // Set the selected category
    fetchBlogsByCategory(category.categoryId); // Fetch blogs of the selected category
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    const cleanText = stripHtmlTags(text);
    return cleanText.length > maxLength
      ? `${cleanText.substring(0, maxLength)}...`
      : cleanText;
  };

  return (
    <Layout>
      <Content>
        <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
          <AdsMobile type="principal" />

          <Heading
            px={[2, 6]}
            pt={[2]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            Blog
          </Heading>

          <Flex w={"100%"} gap={10} justifyContent={"center"}>
            <Flex
              flexDir={"column"}
              display={["none", "none", "flex"]}
              w={"40%"}
              alignItems={"center"}
            >
              <AdsDesktop type={"principal"} w={"100%"} />
              <AdsDesktop type={"secundario"} w={"80%"} />
            </Flex>

            <Flex w={["100%", "100%", "50%"]} flexDir={"column"}>
              <Blogs />
            </Flex>
          </Flex>

          <AdsMobile type="secundario" />

          <Flex w={"100%"} flexDir={"column"}>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              Categorias
            </Heading>
            <Flex
              gap={[1, 2]}
              p={[3, 5]}
              w={"100%"}
              alignItems={"start"}
              wrap={"wrap"}
            >
              {!!categories[0] &&
                categories.map((el) => (
                  <Button
                    variant={"link"}
                    onClick={() => handleCategoryClick(el)}
                  >
                    <Badge
                      p={2}
                      colorScheme="blue"
                      fontSize={"0.9rem"}
                    >{`${el.name} (${el.blogCount})`}</Badge>
                  </Button>
                ))}
            </Flex>
            <Flex flexDir={"column"} p={5}>
              {selectedCategory && (
                <Heading
                  px={[0, 10]}
                  fontSize={"1.5rem"}
                  variant={"outline"}
                  colorScheme="yellow"
                  textDecor={"underline"}
                  alignSelf={"flex-start"}
                  textDecorationColor={"#01acec"}
                >
                  {selectedCategory.name}
                </Heading>
              )}
              <Flex
                gap={[1, 3]}
                p={[1, 5]}
                wrap={"wrap"}
                justifyContent={"center"}
              >
                {selectedCategory &&
                  !!blogs?.blogItems &&
                  blogs.blogItems.map((el) => (
                    <Flex
                      key={el.blogId + el.authorId}
                      gap={3}
                      border={"1px solid #ccc"}
                      p={[2, 4]}
                      borderRadius={"1rem"}
                      w={["100%", "100%", "45%"]}
                    >
                      <Image
                        w={["8rem", "8rem", "9rem"]}
                        h={["8rem", "8rem", "9rem"]}
                        objectFit="cover"
                        borderRadius="1rem"
                        src={`https://amni-prod.s3.amazonaws.com/blog_images/${el?.images[0]}`}
                        aspectRatio={1}
                        overflow="hidden"
                      />
                      <Flex
                        flexDir={"column"}
                        w={"100%"}
                        flexGrow={1}
                        justifyContent={"space-between"}
                      >
                        <Flex flexDir={"column"}>
                          <Text fontWeight={600}>{el.title}</Text>
                          <Text fontSize={"0.8rem"} fontStyle={"italic"}>
                            Autor: {el.authorName}
                          </Text>
                          <Text>{truncateText(el.text, 100)}</Text>
                        </Flex>
                        <Link to={`/blog/${el.blogId}`}>
                          <Button size={"xs"} colorScheme="blue">
                            Ler mais...
                          </Button>
                        </Link>
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Content>
    </Layout>
  );
};
export default Blog;
