import {
  Box,
  Flex,
  Heading,
  Text,
  Divider,
  Image,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <Flex
      justifyContent="center" // Centraliza horizontalmente
      alignItems="center"
      py={10}
      px={[4, 8, 16]}
      bgColor="#f7f9fc"
    >
      <Box
        width="100%"
        maxWidth="800px" // Limita a largura máxima para manter legibilidade
        textAlign="left" // Texto alinhado à esquerda para leitura fluida
        mx="auto" // Centraliza o Box dentro do Flex
      >
        <Link to="https://academia-nmi.blogspot.com/">
          <Button variant={"link"} colorScheme="blue" mb={5}>
            Visite o Antigo Blog{" "}
          </Button>
        </Link>

        <Heading
          px={[2, 6]}
          pt={[2]}
          pb={4}
          variant="outline"
          colorScheme="yellow"
          textDecor="underline"
          alignSelf="flex-start"
          textDecorationColor="#01acec"
          fontSize={["1.5rem", "2rem"]}
        >
          Quem Somos?
        </Heading>
        <Flex
          flexDir="column"
          gap={6}
          lineHeight="1.8"
          fontSize="1rem"
          color="gray.700"
        >
          <Text>
            A <strong>Academia Nacional de Maçons Imortais-ANMI</strong> foi
            fundada no dia 31 de maio de 2024 por cinquenta maçons, com o quadro
            de membros efetivos completo, entre eles: professores, escritores,
            educadores, gestores educacionais, pedagogos, pesquisadores,
            filósofos, poetas, trovadores, editores de livros e jornais, e
            estadista maçônico.
          </Text>
          <Text>
            A ANMI surgiu para atuar nas áreas literária, cultural e científica,
            na formatação de produtos culturais e educacionais maçônicos, e
            também para atuar na construção de uma comunidade acadêmica no
            ambiente maçônico, em nível nacional.
          </Text>
          <Divider borderColor="blue.200" />
          <Text>
            É a segunda Academia formada por maçons dedicada às letras, arte e
            cultura, criada no Brasil para funcionar no ambiente virtual,
            utilizando-se das novas ferramentas tecnológicas, razão pela qual
            seus membros não usarão fardão acadêmico. Considerada afilhada da
            primeira Academia Virtual: Academia Maçônica Virtual Brasileira de
            Letras.
          </Text>
          <Text>
            Entre seus associados destaca-se o acadêmico, jornalista Jarice
            Braga, editor-chefe do Jornal A Voz do Escriba, que há 34 anos
            divulga semanalmente o dia a dia da Maçonaria no Brasil, com
            destaque para artigos sobre história, letras, cultura, Potências
            Maçônicas, biografias de vultos importantes que, como maçons,
            escreveram a história da Maçonaria e do Brasil.
          </Text>
          <Divider borderColor="blue.200" />
          <Text>
            Esta Academia pretende divulgar seus produtos acadêmicos pelas redes
            sociais, boletins, jornais, revistas, coletâneas, antologias, bem
            como através de intercâmbio cultural com suas congêneres e
            parcerias, com destaque para a já celebrada com o jornal A Voz do
            Escriba, no qual se pretende publicar semanalmente artigos de
            autoria de seus acadêmicos e a palavra de seu presidente.
          </Text>
          <Divider borderColor="blue.200" />
          <Flex
            p={[2, 4]}
            bg="yellow.50"
            borderRadius="md"
            boxShadow="md"
            alignItems={"center"}
            gap={5}
            flexDir={["column", "row"]}
          >
            <Image
              bgColor={"white"}
              src="https://amni-prod.s3.amazonaws.com/user_images/userPhoto-5fdcca5d-8d9f-4533-9beb-faf1f34d836d.jpeg"
              boxSize="200px"
              boxShadow={"md"}
              border={"2px solid #cccccc"}
              borderRadius={"100%"}
              objectFit="cover"
              aspectRatio={1}
            />
            <Flex flexDir={"column"}>
              <Text fontWeight="bold">HELIO P. LEITE</Text>
              <Text>Presidente da ANMI</Text>
              <Text>Cadeira 33</Text>
              <Text>Patrono: Joaquim Gonçalves Ledo</Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AboutSection;
