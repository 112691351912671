import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import client from "../../utils/client";
import { useAuth } from "../../context/AuthContext";

const BlogItem = ({ onDelete, blog }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const auth = useAuth();

  // Função para atualizar as categorias ao excluir um blog
  const updateCategoriesOnDelete = async () => {
    try {
      for (const categoryId of blog.categories) {
        // Remove o blogId da categoria e decrementa o blogCount
        await client.patch(`/categories/${categoryId}/removeBlog`, {
          blogId: blog.blogId,
        });
      }
    } catch (error) {
      console.error("Erro ao atualizar categorias na exclusão do blog:", error);
      toast({
        title: "Erro ao atualizar categorias",
        description:
          "Não foi possível atualizar as categorias ao excluir o blog.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      // Atualiza as categorias antes de excluir o blog
      await updateCategoriesOnDelete();

      // Chama a função de exclusão do blog
      await onDelete(blog.blogId);

      toast({
        title: "Blog excluído com sucesso",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erro ao excluir o blog:", error);
      toast({
        title: "Erro ao excluir blog",
        description: "Não foi possível excluir o blog.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{blog.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={"90%"} gap={3} flexDir={"column"}>
              <Text>
                {blog.authorName} -{" "}
                {format(new Date(blog.createdAt), "dd/MM/yyyy")}
              </Text>
              <Text
                dangerouslySetInnerHTML={{
                  __html: blog.text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                }}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td>{blog.title}</Td>
        <Td>Ir∴ {blog.authorName}</Td>
        <Td>{format(new Date(blog.createdAt), "dd/MM/yyyy")}</Td>
        <Td justifySelf={"center"}>{blog.comments.length}</Td>
        <Td>
          <Flex gap={5}>
            <Tooltip label={"Detalhes"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>

            {auth?.user.id === blog.authorId && (
              <>
                <Tooltip label={"Editar postagem"}>
                  <IconButton
                    colorScheme="yellow"
                    aria-label="Editar"
                    size="sm"
                    icon={<EditIcon />}
                    onClick={() => {
                      navigate(`/editar_postagem/${blog.blogId}`);
                    }}
                  />
                </Tooltip>
                <Tooltip label={"Excluir postagem"}>
                  <IconButton
                    colorScheme="red"
                    aria-label="Excluir"
                    size="sm"
                    isLoading={isLoading}
                    onClick={handleDelete}
                    icon={<DeleteIcon />}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default BlogItem;
