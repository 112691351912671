import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  useToast,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import client from "../utils/client";
import { v4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { myBucket } from "../utils/bucket";
import { DeleteIcon } from "@chakra-ui/icons"; // Ícone de exclusão de capa
import NotAuthorized from "./NotAuthorized";

const AddBook = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { bookId } = useParams();
  const auth = useAuth();
  const toast = useToast();
  const [bookCover, setBookCover] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingBook, setLoadingBook] = useState(true);

  const navigate = useNavigate();
  const [removedCover, setRemovedCover] = useState(null);

  // Adiciona o useEffect para buscar os dados do livro para edição
  useEffect(() => {
    const fetchBook = async () => {
      if (bookId) {
        try {
          const response = await client.get(`/books/${bookId}`);
          const bookData = response.data;

          // Popula os campos do formulário com os dados recebidos
          setValue("bookName", bookData.bookName);
          setValue("bookAuthor", bookData.bookAuthor);

          // Configura a capa se existir
          if (bookData.bookImage !== "default") {
            setBookCover([
              {
                preview: `https://${process.env.REACT_APP_S3_BOOKSIMAGES}.s3.amazonaws.com/${bookData.bookImage}`,
              },
            ]);
          }

          setLoadingBook(false); // Marca como carregado
        } catch (error) {
          console.error("Erro ao buscar o livro:", error);
          toast({
            title: "Erro ao carregar o livro",
            description: "Não foi possível carregar os dados do livro",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setLoadingBook(false);
        }
      } else {
        setLoadingBook(false); // Se não houver `bookId`, para de carregar
      }
    };

    fetchBook();
  }, [bookId, setValue, toast]);

  const uploadFile = async (file, bucket, fileId) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: bucket,
      Key: fileId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onDrop = (acceptedFiles) => {
    const newCover = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setBookCover(newCover); // Apenas uma capa por livro
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1, // Limitar para uma capa por livro
  });

  const onDeleteCover = (preview) => {
    setRemovedCover(bookCover[0]?.preview.split("/").pop()); // Define a capa a ser removida do bucket
    setBookCover([]); // Remove a capa da interface
  };

  const deleteImageFromBucket = async (imageId) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BOOKSIMAGES,
      Key: imageId,
    };

    myBucket.deleteObject(params, (err, data) => {
      if (err) {
        console.log(`Erro ao excluir a capa ${imageId} do bucket`, err);
      }
    });
  };

  const onSubmit = async (el) => {
    setIsLoading(true);
    try {
      let coverUrl = "default"; // Valor default para a capa
      const bookFileId = `book-${v4()}.pdf`;

      // Faz o upload do livro
      await uploadFile(
        el.bookFile[0],
        process.env.REACT_APP_S3_BOOKS,
        bookFileId
      );

      if (bookCover[0]) {
        const coverId = `bookCover-${v4()}.jpeg`;
        await uploadFile(
          bookCover[0],
          process.env.REACT_APP_S3_BOOKSIMAGES,
          coverId
        );
        coverUrl = coverId;
      }

      // Exclui a capa removida do bucket, se necessário
      if (removedCover) {
        await deleteImageFromBucket(removedCover);
      }

      const payload = {
        bookName: el.bookName,
        bookAuthor: el.bookAuthor,
        bookLink: `https://amni-prod.s3.amazonaws.com/books/${bookFileId}`,
        bookCover: coverUrl, // Usa a capa carregada ou "default"
        addedBy: auth.user.id, // O usuário que adicionou o livro
      };

      if (bookId) {
        // Atualiza o livro existente
        await client.patch(`/books/${bookId}`, payload);
        toast({
          title: "Livro atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        // Adiciona um novo livro
        await client.post("/books", payload);
        toast({
          title: "Livro criado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      navigate("/gerenciamento_biblioteca");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  if (loadingBook) {
    return (
      <Flex justifyContent="center" alignItems="center" height="50vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_LIBRARY) ? (
        <Layout>
          <Content>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              {bookId ? "Editar Livro" : "Adicionar Livro"}
            </Heading>
            <Flex
              p={[1, 5]}
              w={"50%"}
              justifyContent={"center"}
              m={"auto"}
              flexDir={"column"}
              gap={5}
            >
              <Text
                fontSize={"1.5rem"}
                fontWeight={700}
                color={"#d41717"}
                fontStyle={"italic"}
                textAlign={"center"}
              >
                Leia antes de adicionar um livro ou trabalho:
              </Text>
              <Text>
                <strong>1-</strong> Mantenha, na medida do possível, o tamanho
                máximo de até <u>5 MB</u> por arquivo
              </Text>
              <Text>
                <strong>2-</strong> Arquivos com mais de <u>50 MB</u> oneram
                demais os custos de armazenamento
              </Text>
              <Text>
                <strong>3-</strong> Encaminhe para o nosso bibliotecário a
                autorização do autor e ou do dono dos direitos autorais e
                comerciais para a inclusão de cada título no acervo da
                biblioteca da ANMI.
              </Text>
            </Flex>
            <Flex alignItems={"center"} flexDir={"column"}>
              <Box w={"100%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex
                    gap={5}
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Input
                      {...register("bookName")}
                      placeholder="Título do Livro"
                    />
                    <Input
                      {...register("bookAuthor")}
                      placeholder="Autor do Livro"
                    />
                    <Input
                      type="file"
                      {...register("bookFile")}
                      accept="application/pdf"
                      placeholder="Selecione o arquivo PDF do livro"
                    />

                    {/* Seção de upload da capa */}
                    <div {...getRootProps()} style={{ margin: "20px 0" }}>
                      <Flex
                        justifyContent={"center"}
                        bgColor={"#ccc"}
                        p={5}
                        borderRadius={8}
                        minW={"50%"}
                      >
                        <input {...getInputProps()} />
                        <p>
                          {isDragActive
                            ? "Solte a capa aqui..."
                            : "Arraste e solte a capa aqui ou clique para selecionar"}
                        </p>
                      </Flex>
                    </div>

                    <Flex gap={3} wrap={"wrap"}>
                      {bookCover.length > 0 && (
                        <Flex
                          flexDir={"column-reverse"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={1}
                          my={3}
                        >
                          <IconButton
                            shadow={"dark-lg"}
                            colorScheme="red"
                            aria-label="Remover capa"
                            size="sm"
                            onClick={() => onDeleteCover(bookCover[0].preview)}
                            icon={<DeleteIcon />}
                          />
                          <Image
                            shadow={"dark-lg"}
                            borderRadius={8}
                            src={bookCover[0].preview}
                            alt={bookCover[0].name}
                            maxW={"100%"}
                            maxH={"200px"}
                          />
                        </Flex>
                      )}
                    </Flex>

                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      {bookId ? "Atualizar Livro" : "Adicionar Livro"}
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default AddBook;

//https://amni-prod.s3.amazonaws.com/amni-prod/books/book-cb38fb25-36de-4463-a243-f0fd7ac99320.pdf
//https://amni-prod.s3.amazonaws.com/books/book-cb38fb25-36de-4463-a243-f0fd7ac99320.pdf
