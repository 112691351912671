import { Flex, Heading } from "@chakra-ui/react";
import Layout from "../layout/Layout";
import Content from "../layout/Content";
import Carousel from "../components/Carousel";
import News from "../components/News";
//import AdsMobile from "../components/AdsMobile";
//import AdsDesktop from "../components/AdsDesktop";
import { useEffect, useState } from "react";
import client from "../utils/client";
import AboutSection from "../components/AboutSection";
import AdsDesktop from "../components/AdsDesktop";
import AdsMobile from "../components/AdsMobile";

const Home = () => {
  const [lastNews, setLastNews] = useState();

  useEffect(() => {
    const request = async () => {
      const lastNews = await client.get("/news/lastNews");
      setLastNews(lastNews.data);
    };
    request();
  }, []);

  return (
    <Layout>
      <Content>
        <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
          <AdsMobile type="principal" />

          <Heading
            px={[2, 6]}
            pt={[2]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            Últimas Notícias
          </Heading>

          {!!lastNews && (
            <Flex p={2} w={["100%", "100%", "80%"]} zIndex={"80"}>
              <Carousel
                routeImage={"news_images"}
                items={lastNews}
                maxH={["25vh", "30vh", "40vh"]}
                target={"news"}
              />
            </Flex>
          )}

          <Flex w={"100%"} gap={10} justifyContent={"center"}>
            <Flex
              flexDir={"column"}
              display={["none", "none", "flex"]}
              w={"40%"}
              alignItems={"center"}
            >
              <AdsDesktop type={"principal"} w={"100%"} />
              <AdsDesktop type={"secundario"} w={"80%"} />
            </Flex>

            <Flex w={["100%", "100%", "50%"]} flexDir={"column"}>
              <News />
            </Flex>
          </Flex>
          <AdsMobile type="secundario" />
          <AboutSection />
        </Flex>
      </Content>
    </Layout>
  );
};

export default Home;
