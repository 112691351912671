import { Flex } from "@chakra-ui/react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ children }) => {
  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-between"}
      minH={"100vh"}
      bgGradient={"linear(to-b, #fffacd, #ffffff)"}
      w={"100%"}
    >
      <Header />
      <Flex flexGrow={1} maxW={"100%"} m={2} justifyContent={"center"}>
        {children}
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Layout;
