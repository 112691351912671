import { Flex, useBreakpointValue } from "@chakra-ui/react";

const Content = ({ children }) => {
  // Define qual layout renderizar com base no tamanho da tela
  const isDesktop = useBreakpointValue({ base: false, lg: true }); // true para telas lg (desktop) e maiores

  return (
    <Flex w="100%">
      {isDesktop ? (
        <Flex
          flexDir="column"
          bgColor="#ffffff"
          w="80%"
          mx="auto"
          p={5}
          borderRadius={8}
        >
          {children} {/* Renderiza para Desktop */}
        </Flex>
      ) : (
        <Flex w="100%" flexDir="column" bgColor="#ffffff" borderRadius={8}>
          {children} {/* Renderiza para Mobile e Tablet */}
        </Flex>
      )}
    </Flex>
  );
};

export default Content;
