import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASEURL;

const client = axios.create({
  baseURL,
});

client.interceptors.request.use(
  (config) => {
    // Obtém o token de autenticação do armazenamento local
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user ? user.token : null;

    // Adiciona o token ao cabeçalho Authorization se estiver disponível
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
