import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import client from "../../utils/client";
import { Link } from "react-router-dom";

const News = () => {
  //const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //const [loading, setLoading] = useState(false);

  const fetchNews = async (page) => {
    //setLoading(true);
    try {
      const response = await client.get(`/news?page=${page}`);
      setNewsItems(response.data.newsItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      //setLoading(false);
    } catch (error) {
      console.error("Failed to fetch news:", error);
    }
  };

  useEffect(() => {
    fetchNews(currentPage);
  }, [currentPage]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    const cleanText = stripHtmlTags(text);
    return cleanText.length > maxLength
      ? `${cleanText.substring(0, maxLength)}...`
      : cleanText;
  };

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      gap={5}
      p={2}
    >
      <Flex flexDir={"column"} gap={"1rem"}>
        {!!newsItems[0] &&
          newsItems.map((news) => (
            <Flex gap={5} key={uuidv4()}>
              <Image
                w={["7rem", "7rem", "8rem"]}
                h={["7rem", "7rem", "8rem"]}
                src={`https://amni-prod.s3.amazonaws.com/news_images/${news.images[0]}`}
                borderRadius={"1rem"}
                objectFit={"cover"}
              />
              <Flex
                flexDir={"column"}
                w={"100%"}
                flexGrow={1}
                justifyContent={"space-between"}
              >
                <Flex flexDir={"column"}>
                  <Text fontWeight={600}>{news.title}</Text>
                  <Text fontSize={"0.8rem"} fontStyle={"italic"}>
                    Pelo Ir∴ {news.authorName}
                  </Text>
                  <Text>{truncateText(news.text, 100)}</Text>
                </Flex>
                <Link to={`/news/${news.newsId}`}>
                  <Button size={"xs"} w={["30%", "25%"]} colorScheme="blue">
                    Ler mais...
                  </Button>
                </Link>
              </Flex>
            </Flex>
          ))}
      </Flex>

      <Flex mt={4} justifyContent="center" alignItems="center" gap={2}>
        {pages.map((page) => (
          <Button
            key={page}
            size="xs"
            colorScheme="blue"
            variant={currentPage === page ? "outline" : "solid"}
            onClick={() => goToPage(page)}
            isDisabled={currentPage === page}
          >
            {page}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default News;
