import { Flex, Heading } from "@chakra-ui/react";
import Layout from "../layout/Layout";

const NotAuthorized = () => {
  return (
    <Layout>
      <Flex
        padding="6"
        boxShadow="lg"
        bg="white"
        mt={[8]}
        justifyContent={"center"}
      >
        <Heading
          fontFamily={"Aleo"}
          variant={"outline"}
          colorScheme="green"
          textDecor={"underline"}
          textDecorationColor={"#B50B16"}
          color={"#DC1522"}
        >
          VOCÊ NÃO TEM AUTORIZAÇÃO PARA VER ESTA PÁGINA!
        </Heading>
      </Flex>
    </Layout>
  );
};
export default NotAuthorized;
