import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import client from "../utils/client";
import Content from "../layout/Content";
import Layout from "../layout/Layout";

const Recover = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { register: registerA, handleSubmit: handleSubmitA } = useForm();
  const toast = useToast();

  const handleSubmitLogin = async (data) => {
    setIsLoading(true);
    try {
      await client.patch(`/users/changePassword`, data).then(() => {
        setIsLoading(false);
        toast({
          title: "Senha alterada",
          description: "Senha alterada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu.",
        description: "Contate o administrador do sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSendEmail = async (data) => {
    setIsLoading(true);
    try {
      await client.post(`/users/recover`, data).then(() => {
        setIsLoading(false);
        toast({
          title: "Email enviado",
          description: "Verifique também sua caixa de spam",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Erro ao enviar o email: ", error);
      toast({
        title: "Email não enviado",
        description: "Contate o administrador do sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Layout>
      <Content>
        <Flex mt={5} justifyContent={"center"}>
          <FormControl
            border={"1px solid #ccc"}
            borderRadius={"8px"}
            p={[2, 5]}
            bgColor={"white"}
            isRequired
            w={["90%", "80%", "80%", "40%"]}
            padding="6"
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Heading textDecor={"underline"} textDecorationColor={"#01acec"}>
              Recuperar Senha
            </Heading>
            <form id="a" onSubmit={handleSubmitA(handleSubmitLogin)}>
              <Flex mt={5} gap={5} flexDir={"column"} alignItems={"center"}>
                <Box w={"100%"}>
                  <FormLabel htmlFor="newPassword">Nova Senha</FormLabel>
                  <Input type="password" {...registerA("newPassword")} />
                </Box>

                <Input type="hidden" value={token} {...registerA("token")} />

                <Button
                  w={"40%"}
                  isLoading={isLoading}
                  type="submit"
                  color="#01acec"
                  variant="outline"
                >
                  Mudar Senha
                </Button>
              </Flex>
            </form>
            <Flex justifyContent="center" mt={5}>
              <Button
                w={"40%"}
                isLoading={isLoading}
                onClick={() => handleSendEmail({ token })}
                color="#01acec"
                variant="outline"
              >
                Enviar Email de Recuperação
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Content>
    </Layout>
  );
};

export default Recover;
