import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useNavigate } from "react-router-dom";
import BlogItem from "../components/BlogItem"; // Renomear componente para blogs
import NotAuthorized from "./NotAuthorized";
import { useAuth } from "../context/AuthContext";

const AllBlogs = () => {
  const navigate = useNavigate();
  const [blogItems, setBlogItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const fetchBlogs = async (page) => {
    setLoading(true);
    try {
      const response = await client.get(`/blogs?page=${page}`); // Mudança para blogs
      setBlogItems(response.data.blogItems); // Atualiza para blogs
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch blogs:", error); // Atualiza mensagem de erro
    }
  };

  useEffect(() => {
    fetchBlogs(currentPage); // Atualiza para blogs
  }, [currentPage]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDelete = async (blogId) => {
    // Atualiza para blogId
    await client.delete(`/blogs/${blogId}`); // Mudança para blogs
    setBlogItems(blogItems.filter((blog) => blog.blogId !== blogId)); // Atualiza para blogs
  };

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_CORRESPONDENT) ||
      auth?.user.type.includes(process.env.REACT_APP_FELLOWMEMBER) ? (
        <Layout>
          <Content>
            <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
              <Flex
                display={["flex", "flex", "none"]}
                px={2}
                w={"100%"}
                zIndex={"80"}
              >
                {/* <Carousel items={ads} maxH={["30vh", "30vh", "40vh"]} /> */}
              </Flex>

              <Heading
                px={[2, 6]}
                pt={[2]}
                variant={"outline"}
                colorScheme="yellow"
                textDecor={"underline"}
                alignSelf={"flex-start"}
                textDecorationColor={"#01acec"}
              >
                Gerenciar Blogs {/* Atualiza para Blogs */}
              </Heading>
              <Button
                colorScheme="green"
                onClick={() => navigate("/adicionar_blog")} // Atualiza para adicionar_blog
              >
                Adicionar Blog {/* Atualiza para Blog */}
              </Button>

              <Flex w={"100%"}>
                <Box p={5} w={"100%"}>
                  <TableContainer>
                    <Table variant="striped" size={"sm"}>
                      <Thead>
                        <Tr>
                          <Th>Título</Th>
                          <Th>Autor</Th>
                          <Th>Data de Criação</Th>
                          <Th>Qtd Comentários</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loading
                          ? // Skeleton enquanto os dados estão sendo carregados
                            [...Array(3)].map((_, index) => (
                              <Tr key={index}>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                              </Tr>
                            ))
                          : // Renderiza os dados quando disponíveis
                            blogItems.map(
                              (
                                blog // Atualiza para blogItems
                              ) => (
                                <BlogItem // Componente atualizado para BlogItem
                                  key={blog.blogId} // Atualiza para blogId
                                  onDelete={handleDelete}
                                  blog={blog} // Atualiza para blog
                                />
                              )
                            )}
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {/* Controles de Paginação */}
                  <Flex
                    mt={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      onClick={goToPreviousPage}
                      isDisabled={currentPage === 1}
                      colorScheme="blue"
                    >
                      Anterior
                    </Button>

                    <Text>
                      Página {currentPage} de {totalPages}
                    </Text>

                    <Button
                      onClick={goToNextPage}
                      isDisabled={currentPage === totalPages}
                      colorScheme="blue"
                    >
                      Próxima
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};
export default AllBlogs; // Atualiza o nome do componente para AllBlogs
