import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Box, Image, Text } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const Carousel = ({ items, maxH, routeImage, justImage, adjustImages }) => {
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    const cleanText = stripHtmlTags(text);
    return cleanText.length > maxLength
      ? `${cleanText.substring(0, maxLength)}...`
      : cleanText;
  };

  return (
    !!items[0] && (
      <Swiper
        style={{ width: "100%", height: "auto" }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
      >
        {items.map((el) => (
          <SwiperSlide key={uuidv4()}>
            {!!el?.title && !!el?.text && !justImage ? (
              <Link to={`/news/${el.newsId}`}>
                <Box position="relative" mx="auto" w="100%">
                  <Image
                    borderRadius="1rem"
                    src={`https://amni-prod.s3.amazonaws.com/${routeImage}/${el?.images[0]}`}
                    filter="brightness(50%)"
                    w="100%"
                    h={maxH ? maxH : "auto"} // Garantir que maxH está sendo aplicado
                    maxHeight={maxH} // Define a altura máxima se maxH for passado
                    objectFit={adjustImages ? "contain" : "cover"} // Ajusta objectFit se adjustImages estiver ativado
                  />
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    color="white"
                    px={4}
                    w={"100%"}
                  >
                    <Text w={"100%"} fontSize="xl" fontWeight="bold">
                      {el.title}
                    </Text>
                    <Text w={"100%"} fontSize={["md", "md", "lg"]}>
                      {truncateText(el.text, 100)}
                    </Text>
                  </Box>
                </Box>
              </Link>
            ) : (
              <Image
                src={`https://amni-prod.s3.amazonaws.com/${routeImage}/${el}`}
                w={"100%"}
                h={maxH ? maxH : "auto"} // Define altura máxima para as imagens
                maxHeight={maxH} // Aplica a altura máxima, se especificada
                objectFit={adjustImages ? "contain" : "cover"} // Ajusta objectFit
                mx={"auto"}
                borderRadius="1rem"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  );
};

export default Carousel;
