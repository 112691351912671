import { Box, Button, Icon, Text } from "@chakra-ui/react";
import { FaCommentDots } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const LogToComment = () => {
  const navigate = useNavigate();
  return (
    <Box
      border="2px"
      borderColor="blue.400"
      borderRadius="lg"
      padding="8"
      textAlign="center"
      background="linear-gradient(135deg, #edf2f7 0%, #e2e8f0 100%)"
      boxShadow="xl"
      maxW="lg"
      margin="auto"
      mt="8"
      _hover={{ transform: "scale(1.05)", transition: "0.3s ease" }}
    >
      <Icon as={FaCommentDots} boxSize="8" color="blue.400" mb="4" />
      <Text fontSize="2xl" fontWeight="bold" color="blue.600">
        Faça login para ler e deixar comentários!
      </Text>
      <Button
        mt={4}
        colorScheme="linkedin"
        onClick={() => navigate("/login")}
        _hover={{ transform: "scale(1.05)", transition: "0.3s ease" }}
      >
        Vá para Login
      </Button>
    </Box>
  );
};
export default LogToComment;
