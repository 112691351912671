import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../utils/client";
import { useAuth } from "../../context/AuthContext";

const BookItem = ({ onDelete, book }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const auth = useAuth();

  // Função para excluir o livro e chamar onDelete
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      // Chama a função de exclusão do livro no backend
      await client.delete(`/books/${book.bookId}`);

      // Chama a função onDelete passada como prop
      await onDelete(book.bookId);

      toast({
        title: "Livro excluído com sucesso",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erro ao excluir o livro:", error);
      toast({
        title: "Erro ao excluir livro",
        description: "Não foi possível excluir o livro.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{book.bookName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={"90%"} gap={3} flexDir={"column"}>
              <Text>Autor: {book.bookAuthor}</Text>
              <Text>Capa: {book.bookCover ? book.bookCover : "Sem capa"}</Text>
              <Text>
                <a
                  href={book.bookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link para o Livro
                </a>
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Tr>
        <Td>{book.bookName}</Td>
        <Td>{book.bookAuthor}</Td>
        <Td>
          <Text noOfLines={1}>
            {book.bookCover ? (
              <a
                href={book.bookCover}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver Capa
              </a>
            ) : (
              "Sem Capa"
            )}
          </Text>
        </Td>
        <Td>
          <Flex gap={5}>
            <Tooltip label={"Detalhes"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>

            {auth?.user.id === book.addedBy && (
              <>
                <Tooltip label={"Editar livro"}>
                  <IconButton
                    colorScheme="yellow"
                    aria-label="Editar"
                    size="sm"
                    icon={<EditIcon />}
                    onClick={() => {
                      navigate(`/editar_livro/${book.bookId}`);
                    }}
                  />
                </Tooltip>
                <Tooltip label={"Excluir livro"}>
                  <IconButton
                    colorScheme="red"
                    aria-label="Excluir"
                    size="sm"
                    isLoading={isLoading}
                    onClick={handleDelete}
                    icon={<DeleteIcon />}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default BookItem;
