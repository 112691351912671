import {
  Flex,
  Heading,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import client from "../utils/client";
import UserCard from "../components/UserCard";

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    const request = async () => {
      const response = await client.get("/users", {
        headers: { Authorization: auth.user.token },
      });
      if (response?.data) {
        const ordered = response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setAllUsers(ordered);
      }
    };
    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [auth.user.token]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilteredUsers(
      allUsers.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_COLABORATOR) ? (
        <Layout>
          <Content>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              Gerenciar Usuários
            </Heading>

            <Flex alignItems={"center"} flexDir={"column"}>
              {isLoading ? (
                <Spinner />
              ) : (
                <Flex
                  w={"100%"}
                  gap={5}
                  wrap={"wrap"}
                  justifyContent={"center"}
                  mt={5}
                >
                  <Input
                    type="text"
                    placeholder="Pesquisar"
                    onChange={handleSearch}
                  />
                  <TableContainer w={"100%"}>
                    <Table size={"xs"} variant="striped" colorScheme="linkedin">
                      <Thead>
                        <Tr>
                          <Th>Nome</Th>
                          <Th>Email</Th>
                          <Th>Celular</Th>
                          <Th>Tipo</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredUsers[0]
                          ? filteredUsers.map((user) => (
                              <UserCard key={user.userId} user={user} />
                            ))
                          : allUsers[0] &&
                            allUsers.map((user) => (
                              <UserCard key={user.userId} user={user} />
                            ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              )}
            </Flex>
          </Content>
        </Layout>
      ) : (
        <>Não autorizado</>
      )}
    </>
  );
};
export default AllUsers;
