import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import client from "../utils/client";
import { v4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { myBucket } from "../utils/bucket";
import { DeleteIcon } from "@chakra-ui/icons";
import NotAuthorized from "./NotAuthorized";

const AddAdvertiser = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { advertiserId } = useParams(); // Check if we are in edit mode
  const auth = useAuth();
  const toast = useToast();
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAdvertiser, setLoadingAdvertiser] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (advertiserId) {
      // Fetch existing advertiser for editing
      const fetchAdvertiser = async () => {
        try {
          const response = await client.get(`/advertisers/${advertiserId}`);
          const advertiserData = response.data;
          setValue("advertiserName", advertiserData.advertiserName);
          setValue("advertiserType", advertiserData.advertiserType);
          setValue("expirationDate", advertiserData.expirationDate);
          setValue("targetUrl", advertiserData.targetUrl);
          setImage({
            preview: `https://amni-prod.s3.amazonaws.com/advertisers_images/${advertiserData.advertiserImage}`,
          });
        } catch (error) {
          console.error("Error fetching advertiser:", error);
          toast({
            title: "Erro ao carregar o anunciante para edição",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } finally {
          setLoadingAdvertiser(false);
        }
      };
      fetchAdvertiser();
    } else {
      setLoadingAdvertiser(false);
    }
  }, [advertiserId, setValue, toast]);

  const uploadFile = async (file, photoId) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_S3_ADVERTISERSIMAGES,
      Key: photoId,
    };

    return new Promise((resolve, reject) => {
      myBucket.putObject(params, (err) => {
        if (err) reject(err);
        else resolve();
      });
    });
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(Object.assign(file, { preview: URL.createObjectURL(file) }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false, // Allow only one image per advertiser
  });

  const onDelete = () => {
    setImage(null);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      let imageUrl = "";

      if (image && image.preview.startsWith("blob:")) {
        const imageId = `advertiserPhoto-${v4()}.jpeg`;
        await uploadFile(image, imageId);
        imageUrl = imageId;
      } else if (image) {
        imageUrl = image.preview.split("/").pop();
      }

      const payload = {
        advertiserName: data.advertiserName,
        advertiserType: data.advertiserType,
        expirationDate: data.expirationDate,
        targetUrl: data.targetUrl,
        advertiserImage: imageUrl,
      };

      if (advertiserId) {
        // Atualiza o anunciante existente usando PATCH
        await client.patch(`/advertisers/${advertiserId}`, payload);
        toast({
          title: "Anunciante atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        // Adiciona um novo anunciante usando POST
        await client.post("/advertisers", payload);
        toast({
          title: "Anunciante criado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      navigate("/gerenciamento_anunciantes");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  if (loadingAdvertiser) {
    return (
      <Flex justifyContent="center" alignItems="center" height="50vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_COLABORATOR) ? (
        <Layout>
          <Content>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              {advertiserId ? "Editar Anunciante" : "Adicionar Anunciante"}
            </Heading>
            <Flex mt={5} alignItems={"center"} flexDir={"column"}>
              <Box w={"100%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex
                    gap={5}
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Text
                      color="#b30b0b"
                      fontSize={"0.8rem"}
                      fontStyle={"italic"}
                    >
                      Todos os campos são obrigatórios
                    </Text>
                    <Flex
                      w={"100%"}
                      border={"1px solid #ccc"}
                      p={[1, 5]}
                      borderRadius={"1rem"}
                      flexDir={["column"]}
                      gap={[1, 5]}
                    >
                      <Flex gap={[1, 5]} flexDir={["column", "row"]} w={"100%"}>
                        <FormControl>
                          <FormLabel fontWeight={600}>
                            Nome do Anunciante
                          </FormLabel>
                          <Input
                            w={"100%"}
                            {...register("advertiserName", { required: true })}
                            placeholder="XYZ LTDA..."
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel fontWeight={600}>
                            Tipo do Anunciante
                          </FormLabel>
                          <Select
                            w={"100%"}
                            {...register("advertiserType", { required: true })}
                          >
                            <option selected disabled>
                              Selecione uma opção
                            </option>
                            <option value="principal">Principal</option>
                            <option value="secundario">Secundário</option>
                          </Select>
                        </FormControl>
                      </Flex>
                      <Flex gap={[1, 5]} flexDir={["column", "row"]} w={"100%"}>
                        <FormControl>
                          <FormLabel fontWeight={600}>
                            Data de expiração
                          </FormLabel>
                          <Input
                            {...register("expirationDate", { required: true })}
                            type="date"
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel fontWeight={600}>
                            Site de destino do anúncio
                          </FormLabel>
                          <Input
                            {...register("targetUrl", { required: true })}
                            placeholder="URL de Destino"
                          />
                        </FormControl>
                      </Flex>
                    </Flex>
                    <div
                      {...getRootProps()}
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <Flex
                        justifyContent={"center"}
                        bgColor={"#ccc"}
                        p={5}
                        borderRadius={8}
                        minW={"50%"}
                      >
                        <input {...getInputProps()} />
                        <p>
                          {isDragActive
                            ? "Solte a imagem aqui..."
                            : "Arraste e solte a imagem aqui ou clique para selecionar"}
                        </p>
                      </Flex>
                    </div>
                    {image && (
                      <Flex
                        flexDir={"column-reverse"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                        my={3}
                      >
                        <IconButton
                          shadow={"dark-lg"}
                          colorScheme="red"
                          aria-label="Remover imagem"
                          size="sm"
                          onClick={onDelete}
                          icon={<DeleteIcon />}
                        />
                        <Image
                          shadow={"dark-lg"}
                          borderRadius={8}
                          src={image.preview}
                          alt={image.name}
                          maxW={"100%"}
                          maxH={"200px"}
                        />
                      </Flex>
                    )}
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      {advertiserId
                        ? "Atualizar Anunciante"
                        : "Adicionar Anunciante"}
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default AddAdvertiser;
