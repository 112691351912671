import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useNavigate } from "react-router-dom";
import BookItem from "../components/BookItem"; // Componente para renderizar cada livro
import NotAuthorized from "./NotAuthorized";
import { useAuth } from "../context/AuthContext";

const AllBooks = () => {
  const navigate = useNavigate();
  const [bookItems, setBookItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const fetchBooks = async (page) => {
    setLoading(true);
    try {
      const response = await client.get(`/books?page=${page}`); // Requisição para a rota de livros com paginação
      setBookItems(response.data.books); // Atualiza com os livros recebidos
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch books:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks(currentPage); // Chama a função para buscar os livros
  }, [currentPage]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDelete = async (bookId) => {
    // Função para deletar um livro
    await client.delete(`/books/${bookId}`); // Requisição para deletar o livro
    setBookItems(bookItems.filter((book) => book.bookId !== bookId)); // Remove o livro da lista
  };

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_LIBRARY) ? (
        <Layout>
          <Content>
            <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
              <Heading
                px={[2, 6]}
                pt={[2]}
                variant={"outline"}
                colorScheme="yellow"
                textDecor={"underline"}
                alignSelf={"flex-start"}
                textDecorationColor={"#01acec"}
              >
                Gerenciar Livros
              </Heading>
              <Button
                colorScheme="green"
                onClick={() => navigate("/adicionar_livro")} // Rota para adicionar um novo livro
              >
                Adicionar Livro
              </Button>

              <Flex w={"100%"}>
                <Box p={5} w={"100%"}>
                  <TableContainer>
                    <Table variant="striped" size={"sm"}>
                      <Thead>
                        <Tr>
                          <Th>Título</Th>
                          <Th>Autor</Th>
                          <Th>Capa</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loading
                          ? // Exibe Skeleton enquanto os dados são carregados
                            [...Array(3)].map((_, index) => (
                              <Tr key={index}>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                                <Td>
                                  <Skeleton height="20px" />
                                </Td>
                              </Tr>
                            ))
                          : // Renderiza os livros quando disponíveis
                            bookItems.map((book) => (
                              <BookItem
                                key={book.bookId}
                                onDelete={handleDelete}
                                book={book} // Passa os dados do livro para o componente BookItem
                              />
                            ))}
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {/* Controles de Paginação */}
                  <Flex
                    mt={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      onClick={goToPreviousPage}
                      isDisabled={currentPage === 1}
                      colorScheme="blue"
                    >
                      Anterior
                    </Button>

                    <Text>
                      Página {currentPage} de {totalPages}
                    </Text>

                    <Button
                      onClick={goToNextPage}
                      isDisabled={currentPage === totalPages}
                      colorScheme="blue"
                    >
                      Próxima
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default AllBooks;
