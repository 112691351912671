import { createHashRouter } from "react-router-dom";
import Home from "./routes/Home";
import Institucional from "./routes/Institucional";
import Blog from "./routes/Blog";
import Biblioteca from "./routes/Biblioteca";
import Login from "./routes/Login";
import Register from "./routes/Register";
import AllNews from "./routes/AllNews";
import { RequireAuth } from "./context/AuthContext";
import AddNews from "./routes/AddNews";
import DetailNews from "./routes/DetailNews";
import AllUsers from "./routes/AllUsers";
import AllBlogs from "./routes/AllBlogs";
import AddBlog from "./routes/AddBlog";
import ReadBlog from "./routes/ReadBlog";
import Recover from "./routes/Recover";
import AllAdvertisers from "./routes/AllAdvertisers";
import AddAdvertiser from "./routes/AddAdvertiser";
import AllBooks from "./routes/AllBooks";
import AddBook from "./routes/AddBook";

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/academicos",
    element: <Institucional />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:blogId",
    element: <ReadBlog />,
  },
  {
    path: "/biblioteca",
    element: <Biblioteca />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/cadastro",
    element: <Register />,
  },
  {
    path: "/news/:newsId",
    element: <DetailNews />,
  },
  {
    path: "/gerenciamento_noticias",
    element: (
      <RequireAuth>
        <AllNews />
      </RequireAuth>
    ),
  },
  {
    path: "/gerenciamento_anunciantes",
    element: (
      <RequireAuth>
        <AllAdvertisers />
      </RequireAuth>
    ),
  },
  {
    path: "/gerenciamento_biblioteca",
    element: (
      <RequireAuth>
        <AllBooks />
      </RequireAuth>
    ),
  },
  {
    path: "/gerenciamento_usuarios",
    element: (
      <RequireAuth>
        <AllUsers />
      </RequireAuth>
    ),
  },
  {
    path: "/gerenciamento_postagens",
    element: (
      <RequireAuth>
        <AllBlogs />
      </RequireAuth>
    ),
  },
  {
    path: "/adicionar_noticia",
    element: (
      <RequireAuth>
        <AddNews />
      </RequireAuth>
    ),
  },
  {
    path: "/adicionar_anuncio",
    element: (
      <RequireAuth>
        <AddAdvertiser />
      </RequireAuth>
    ),
  },
  {
    path: "/adicionar_livro",
    element: (
      <RequireAuth>
        <AddBook />
      </RequireAuth>
    ),
  },
  {
    path: "/editar_livro/:bookId",
    element: (
      <RequireAuth>
        <AddBook />
      </RequireAuth>
    ),
  },
  {
    path: "/editar_anunciante/:advertiserId",
    element: (
      <RequireAuth>
        <AddAdvertiser />
      </RequireAuth>
    ),
  },
  {
    path: "/editar_noticia/:newsId",
    element: (
      <RequireAuth>
        <AddNews />
      </RequireAuth>
    ),
  },
  {
    path: "/perfil",
    element: (
      <RequireAuth>
        <Register />
      </RequireAuth>
    ),
  },
  {
    path: "/adicionar_blog",
    element: (
      <RequireAuth>
        <AddBlog />
      </RequireAuth>
    ),
  },
  {
    path: "/editar_postagem/:blogId",
    element: (
      <RequireAuth>
        <AddBlog />
      </RequireAuth>
    ),
  },
  {
    path: "/reset/:token",
    element: <Recover />,
  },
]);

export default router;
