import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import client from "../../utils/client";
import { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";

const Comments = ({ comments, blogId, setReload, reload, route }) => {
  const auth = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await client.post(`/${route}/${blogId}/comment`, {
      authorName: auth.user.name + " " + auth.user.surname,
      ...data,
    });
    if (response.data) {
      reset();
      setLoading(false);
      setReload(!reload);
    }
  };

  const handleDelete = async (commentId) => {
    setDeleteLoading(true);
    const response = await client.delete(
      `/${route}/${blogId}/comment/${commentId}`
    );
    if (response.data) {
      setReload(!reload);
      setDeleteLoading(false);
    }
  };

  return (
    <Flex
      flexDir={"column"}
      w={["100%", "80%"]}
      mx={"auto"}
      gap={[5]}
      py={[2, 5]}
    >
      <Heading
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor="#01acec"
        fontSize={"1.5rem"}
      >
        Comentários
      </Heading>
      <Flex flexDir={"column"} gap={3}>
        {comments &&
          comments.map((com, index) => (
            <Flex
              key={com.commentId}
              p={[3, 5]}
              border={"1px solid #ccc"}
              boxShadow={"md"}
              borderRadius={6}
              gap={2}
              flexDir={"column"}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex alignItems={"end"} gap={2}>
                  <Heading fontSize={"1rem"}>{com.authorName} </Heading>
                  <Text fontSize={"0.75rem"} fontStyle={"italic"}>
                    {formatDistanceToNow(new Date(com.createdAt), {
                      addSuffix: true, // Adiciona "há" ou "em"
                      locale: ptBR, // Define o idioma como português do Brasil
                    })}
                  </Text>
                </Flex>
                {(auth.user.id === com.authorId ||
                  auth.user.type.includes(
                    process.env.REACT_APP_ADMINISTRATOR
                  )) && (
                  <IconButton
                    size={"xs"}
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    isLoading={deleteLoading}
                    onClick={() => handleDelete(com.commentId)}
                  />
                )}
              </Flex>
              <Text p={2}>{com.commentText}</Text>
            </Flex>
          ))}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            p={5}
            border={"1px solid #ccc"}
            boxShadow={"md"}
            borderRadius={6}
            gap={2}
            flexDir={"column"}
          >
            <Heading fontSize={"1rem"}>Deixe seu Comentário</Heading>

            <Textarea p={2} {...register("commentText")}></Textarea>
            <Input
              {...register("authorId")}
              type="hidden"
              value={auth.user.id}
            />
            <Button
              type="submit"
              alignSelf={"flex-end"}
              w={["70%", "20%"]}
              bgColor="#01acec"
              color={"white"}
              isLoading={loading}
            >
              Comentar
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

export default Comments;
