import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Select,
  Badge,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import client from "../utils/client";
import { v4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { myBucket } from "../utils/bucket";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DeleteIcon, CloseIcon } from "@chakra-ui/icons"; // Adicionado CloseIcon
import NotAuthorized from "./NotAuthorized";

const AddBlog = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { blogId } = useParams();
  const [textarea, setTextarea] = useState("");
  const auth = useAuth();
  const toast = useToast();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingBlog, setLoadingBlog] = useState(true);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fetchBlog, setFetchBlog] = useState();
  const navigate = useNavigate();
  const [removedImages, setRemovedImages] = useState([]);

  // Carregar categorias ao abrir o modal
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await client.get("/categories");

        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast({
          title: "Erro ao carregar categorias",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };
    fetchCategories();
  }, [toast]);

  useEffect(() => {
    if (blogId) {
      const fetchBlog = async () => {
        try {
          const response = await client.get(`/blogs/${blogId}`);
          const blogData = response.data;
          setFetchBlog(response.data);
          setValue("title", blogData.title);
          setTextarea(blogData.text);
          setImages(
            blogData.images.map((img) => ({
              preview: `https://amni-prod.s3.amazonaws.com/blog_images/${img}`,
            }))
          );

          const selCategories = categories.filter((category) =>
            blogData.categories.includes(category.categoryId)
          );

          setSelectedCategories(selCategories || []);
        } catch (error) {
          console.error("Error fetching blog:", error);
          toast({
            title: "Erro ao carregar o blog para edição",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } finally {
          setLoadingBlog(false);
        }
      };
      fetchBlog();
    } else {
      setLoadingBlog(false);
    }
  }, [blogId, setValue, toast, categories]);

  const uploadFile = async (file, photoId) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_S3_BLOGIMAGES,
      Key: photoId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setImages([...images, ...newImage]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const onDelete = (preview) => {
    const updatedImages = images.filter((image) => image.preview !== preview);

    // Adiciona a imagem removida ao estado de imagens removidas se já estiver no bucket
    const removedImage = images.find((image) => image.preview === preview);
    if (!removedImage.preview.startsWith("blob:")) {
      setRemovedImages([
        ...removedImages,
        removedImage.preview.split("/").pop(),
      ]);
    }

    setImages(updatedImages);
  };

  const deleteImageFromBucket = async (imageId) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BLOGIMAGES,
      Key: imageId,
    };

    myBucket.deleteObject(params, (err, data) => {
      if (err) {
        console.log(`Erro ao excluir a imagem ${imageId} do bucket`, err);
      }
    });
  };

  const handleChange = (value) => {
    setTextarea(value);
  };

  // Função para adicionar nova categoria
  const addCategory = async () => {
    if (!newCategory) return;

    try {
      const response = await client.post("/categories", { name: newCategory });
      setCategories([...categories, response.data.category]);
      setNewCategory("");
      toast({
        title: "Categoria criada com sucesso",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating category:", error);
      toast({
        title: "Erro ao criar categoria",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // Função para adicionar uma categoria ao estado de categorias selecionadas
  const handleSelectCategory = (categoryId) => {
    const category = categories.find((cat) => cat.categoryId === categoryId);
    if (
      category &&
      !selectedCategories.some((cat) => cat.categoryId === categoryId)
    ) {
      setSelectedCategories([...selectedCategories, category]);
    }
    onClose();
  };

  // Função para remover uma categoria selecionada
  const handleRemoveCategory = (categoryId) => {
    const updatedCategories = selectedCategories.filter(
      (category) => category.categoryId !== categoryId
    );
    setSelectedCategories(updatedCategories);
  };

  const onSubmit = async (el) => {
    setIsLoading(true);
    try {
      let imagesUrls = [];

      // Processa as imagens que estão na interface
      for (const img of images) {
        if (img.preview.startsWith("blob:")) {
          // Novas imagens
          const imageId = `blogPhoto-${v4()}.jpeg`;
          await uploadFile(img, imageId);
          imagesUrls.push(imageId);
        } else {
          // Imagens existentes
          imagesUrls.push(img.preview.split("/").pop());
        }
      }

      // Exclui as imagens removidas do bucket
      for (const imageId of removedImages) {
        console.log(`Excluindo imagem ${imageId} do bucket`);
        await deleteImageFromBucket(imageId);
      }

      // Verifique se o payload está correto
      console.log("Payload de imagens antes de enviar:", imagesUrls);

      const payload = {
        title: el.title,
        authorId: auth.user.id,
        text: textarea,
        images: imagesUrls, // Aqui estão apenas as imagens que restaram após a exclusão
        categories: selectedCategories.map((cat) => cat.categoryId),
      };

      if (blogId) {
        // Atualiza o blog existente
        await client.patch(`/blogs/${blogId}`, payload);

        // Atualiza as categorias removendo o blog das antigas e adicionando nas novas
        await updateCategories(
          blogId,
          payload.categories,
          fetchBlog.categories
        );

        toast({
          title: "Blog atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        // Adiciona um novo blog
        const response = await client.post("/blogs", payload);
        const newBlogId = response.data.params.Item.blogId;

        // Atualiza as categorias adicionando o blogId nas categorias selecionadas
        await updateCategories(newBlogId, payload.categories);

        toast({
          title: "Blog criado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      navigate("/gerenciamento_postagens");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  // Função para atualizar as categorias associadas ao blog
  const updateCategories = async (
    blogId,
    newCategoryIds,
    oldCategoryIds = []
  ) => {
    const id = blogId;

    try {
      // Verifica se há categorias antigas (o que não será o caso para postagens novas)
      if (oldCategoryIds.length > 0) {
        // Remove o blogId das categorias antigas que não estão mais selecionadas
        const categoriesToRemove = oldCategoryIds.filter(
          (categoryId) => !newCategoryIds.includes(categoryId)
        );

        for (const categoryId of categoriesToRemove) {
          await client.patch(`/categories/${categoryId}/removeBlog`, {
            blogId: id,
          });
        }
      }

      // Adiciona o blogId nas novas categorias selecionadas
      const categoriesToAdd = newCategoryIds.filter(
        (categoryId) => !oldCategoryIds.includes(categoryId)
      );

      for (const categoryId of categoriesToAdd) {
        await client.patch(`/categories/${categoryId}/addBlog`, {
          blogId: id,
        });
      }
    } catch (error) {
      console.error("Erro ao atualizar categorias:", error);
      toast({
        title: "Erro ao atualizar categorias",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (loadingBlog) {
    return (
      <Flex justifyContent="center" alignItems="center" height="50vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_FELLOWMEMBER) ||
      auth?.user.type.includes(process.env.REACT_APP_CORRESPONDENT) ? (
        <Layout>
          <Content>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              {blogId ? "Editar Blog" : "Adicionar Blog"}
            </Heading>
            <Flex mt={5} alignItems={"center"} flexDir={"column"}>
              <Box w={"100%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex
                    gap={5}
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Input
                      {...register("title")}
                      placeholder="Título do Blog"
                    />

                    {/* Badges para categorias selecionadas */}
                    <Flex wrap="wrap" gap={2}>
                      {!!selectedCategories[0] ? (
                        selectedCategories.map((category) => (
                          <Badge
                            key={category.categoryId}
                            colorScheme="blue"
                            display="flex"
                            alignItems="center"
                            px={2}
                            py={1}
                            borderRadius="md"
                          >
                            {category.name}
                            <IconButton
                              size="xs"
                              ml={2}
                              icon={<CloseIcon />}
                              onClick={() =>
                                handleRemoveCategory(category.categoryId)
                              }
                              aria-label="Remover categoria"
                              variant="unstyled"
                            />
                          </Badge>
                        ))
                      ) : (
                        <Spinner />
                      )}
                    </Flex>

                    <ReactQuill
                      theme="snow"
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          ["link", "video"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                        "link",

                        "video",
                      ]}
                      value={textarea}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        fontFamily: "Gleego",
                      }}
                    />

                    {/* Botão para abrir o modal de categorias */}
                    <Button onClick={onOpen} colorScheme="blue">
                      Adicionar Categoria
                    </Button>

                    {/* Modal de adição de categorias */}
                    <Modal isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Adicionar Categoria</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <Select
                            placeholder="Selecione uma categoria"
                            onChange={(e) =>
                              handleSelectCategory(e.target.value)
                            }
                          >
                            {categories.map((category) => (
                              <option
                                key={category.categoryId}
                                value={category.categoryId}
                              >
                                {category.name}
                              </option>
                            ))}
                          </Select>
                          <Box mt={4}>
                            <Input
                              placeholder="Nova Categoria"
                              value={newCategory}
                              onChange={(e) => setNewCategory(e.target.value)}
                            />
                            <Button
                              mt={2}
                              colorScheme="green"
                              onClick={addCategory}
                            >
                              Criar Categoria
                            </Button>
                          </Box>
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Fechar
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>

                    <div
                      {...getRootProps()}
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <Flex
                        justifyContent={"center"}
                        bgColor={"#ccc"}
                        p={5}
                        borderRadius={8}
                        minW={"50%"}
                      >
                        <input {...getInputProps()} />
                        <p>
                          {isDragActive
                            ? "Solte as imagens aqui..."
                            : "Arraste e solte imagens aqui ou clique para selecionar"}
                        </p>
                      </Flex>
                    </div>

                    <Flex gap={3} wrap={"wrap"}>
                      {images.map((file) => (
                        <Flex
                          key={file.preview}
                          flexDir={"column-reverse"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={1}
                          my={3}
                        >
                          <IconButton
                            shadow={"dark-lg"}
                            colorScheme="red"
                            aria-label="Remover imagem"
                            size="sm"
                            onClick={() => onDelete(file.preview)}
                            icon={<DeleteIcon />}
                          />
                          <Image
                            shadow={"dark-lg"}
                            borderRadius={8}
                            src={file.preview}
                            alt={file.name}
                            maxW={"100%"}
                            maxH={"200px"}
                          />
                        </Flex>
                      ))}
                    </Flex>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      {blogId ? "Atualizar Blog" : "Adicionar Blog"}
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default AddBlog;
