import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Biblioteca = () => {
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const toast = useToast();
  const auth = useAuth();
  const navigate = useNavigate();

  // Função para buscar livros com base na página atual
  const fetchBooks = async (page = 1) => {
    setLoading(true);
    try {
      const response = await client.get(`/books?page=${page}`);
      setBooks(response.data.books);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Erro ao carregar livros:", error);
      toast({
        title: "Erro ao carregar livros",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar livros por título
  const searchBooks = async (query) => {
    setLoading(true);
    try {
      const response = await client.get(`/books/search?bookName=${query}`);
      setBooks(response.data);
      setTotalPages(1); // Ao buscar por título, a paginação é desconsiderada
    } catch (error) {
      console.error("Erro ao buscar livros:", error);
      toast({
        title: "Erro ao buscar livros",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Função para baixar o livro
  const handleDownload = (bookLink) => {
    window.open(bookLink, "_blank");
  };

  // Busca os livros quando a página é carregada ou a página atual muda
  useEffect(() => {
    if (!searchQuery) {
      fetchBooks(currentPage);
    }
  }, [currentPage, searchQuery]);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <Layout>
      <Content>
        {auth?.user?.id ? (
          <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
            <Flex
              flexDir={["column", "row"]}
              alignItems={"center"}
              justifyContent={"center"}
              p={[1, 8]}
              mt={3}
            >
              <Image src="/logoBibli.png" maxW={["80%", "50%", "30%"]} />
              <Box>
                <Heading
                  px={[2, 6]}
                  pt={[2]}
                  variant={"outline"}
                  colorScheme="yellow"
                  textDecor={"underline"}
                  alignSelf={"flex-start"}
                  textDecorationColor={"#01acec"}
                  m={"auto"}
                  textAlign={"center"}
                >
                  Biblioteca Virtual Irmão João Darcy Ruggeri
                </Heading>
              </Box>
            </Flex>

            {/* Barra de pesquisa */}
            <Box my={4} w="100%">
              <Input
                placeholder="Buscar por título"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button
                mt={2}
                colorScheme="blue"
                onClick={() => searchBooks(searchQuery)}
              >
                Buscar
              </Button>
              <Button
                mt={2}
                ml={2}
                onClick={() => {
                  setSearchQuery("");
                  fetchBooks(1); // Reseta a busca e volta para a página 1
                }}
              >
                Limpar Busca
              </Button>
            </Box>

            {/* Estante de livros */}
            {!loading ? (
              <Flex
                wrap="wrap"
                justifyContent="center"
                alignItems={"center"}
                gap={6}
              >
                {books.map((book) => (
                  <Flex
                    key={book.bookId}
                    textAlign="center"
                    p={[3, 8]}
                    border={"1px solid #ccc"}
                    borderRadius={"1rem"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Image
                      src={
                        book.bookCover === "default" || !book.bookCover
                          ? "/defBook.png"
                          : `https://amni-prod.s3.amazonaws.com/books_images/${book.bookCover}`
                      }
                      alt={book.bookName}
                      boxSize="150px"
                      m={"auto"}
                      objectFit="fill"
                      cursor="pointer"
                      borderRadius={"0.8rem"}
                      onClick={() => handleDownload(book.bookLink)}
                    />
                    <Flex py={2} mt={3} flexDir={"column"}>
                      <Text fontWeight="bold">{book.bookName}</Text>
                      <Text>{book.bookAuthor}</Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Spinner />
            )}

            {/* Controles de Paginação */}
            {!searchQuery && (
              <Flex mt={4} justifyContent="center" alignItems="center" gap={2}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    size="xs"
                    colorScheme="blue"
                    variant={currentPage === page ? "outline" : "solid"}
                    onClick={() => goToPage(page)}
                    isDisabled={currentPage === page}
                  >
                    {page}
                  </Button>
                ))}
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex flexDir={"column"} gap={20} alignItems={"center"} w={"100%"}>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              Você precisa estar logado para ver esta Página!
            </Heading>
            <Button
              w={["70%", "30%"]}
              colorScheme="linkedin"
              onClick={() => navigate("/login")}
            >
              Ir para Login
            </Button>
          </Flex>
        )}
      </Content>
    </Layout>
  );
};

export default Biblioteca;
