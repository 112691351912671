import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Content from "../layout/Content";
import { useEffect, useState } from "react";
import client from "../utils/client";
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  useBreakpointValue,
  Image,
  Badge,
  Divider,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import { format } from "date-fns";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import Comments from "../components/Comments";
import { useAuth } from "../context/AuthContext";
import LogToComment from "../components/LogToComment";

const ReadBlog = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState();
  const [loading, setLoading] = useState(true);
  const [author, setAuthor] = useState();
  const [reload, setReload] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const request = async () => {
      try {
        const response = await client.get(`/blogs/${blogId}`);
        setBlog(response.data);
        const author = await client.get(`/users/${response.data.authorId}`);
        setAuthor(author.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };
    request();
  }, [blogId, reload]);

  // Determina o padding com base no tamanho da tela
  const paddingX = useBreakpointValue({ base: 4, md: 10 });

  return (
    <Layout>
      <Content>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="50vh">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          !!blog && (
            <>
              <Heading
                px={paddingX}
                pt={4}
                variant="outline"
                colorScheme="yellow"
                textDecor="underline"
                alignSelf="flex-start"
                textDecorationColor="#01acec"
                fontSize={["1.5rem", "2rem"]} // Ajuste de tamanho de fonte para responsividade
              >
                {blog.title}
              </Heading>

              <Flex w="100%" my={5}>
                <Carousel
                  items={blog.images}
                  routeImage="blog_images"
                  adjustImages={true} // Ativa o ajuste de imagens
                  maxH="500px" // Define a altura máxima, se necessário
                />
              </Flex>
              <Flex alignItems={"center"} gap={3} p={[1, 5]}>
                {/* <Image
                  borderRadius={"100%"}
                  w={"5rem"}
                  h={"5rem"}
                  boxShadow={"lg"}
                  objectFit={"cover"}
                  src={
                    author?.photo
                      ? `https://amni-prod.s3.amazonaws.com/user_images/${author.photo}`
                      : "/assets/defUser.webp"
                  }
                /> */}
                <Flex flexDir={"column"}>
                  <Text fontSize="0.85rem" fontStyle="italic" color="gray.600">
                    {author.name} {author.surname},{" "}
                    {formatDistanceToNow(new Date(blog.createdAt), {
                      addSuffix: true, // Adiciona "há" ou "em"
                      locale: ptBR, // Define o idioma como português do Brasil
                    })}
                  </Text>
                  <Flex gap={2}>
                    {author.type.map(
                      (el) =>
                        el !== process.env.REACT_APP_GUEST && (
                          <Badge
                            fontSize={["0.65rem"]}
                            colorScheme="linkedin"
                            p={2}
                            borderRadius={6}
                            textTransform="none"
                            key={el}
                          >
                            {(el === process.env.REACT_APP_ADMINISTRATOR &&
                              "ADMINISTRADOR") ||
                              (el === process.env.REACT_APP_FELLOWMEMBER &&
                                "ACADÊMICO") ||
                              (el === process.env.REACT_APP_CORRESPONDENT &&
                                "CORRESPONDENTE") ||
                              (el === process.env.REACT_APP_COLABORATOR &&
                                "COLABORADOR")}
                          </Badge>
                        )
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                width={["95%", "80%"]}
                maxWidth="800px"
                margin="auto"
                my={[5, 3]}
                bgColor="#f7f7f7"
                padding="20px"
                borderRadius="8px"
                boxShadow="lg" // Adiciona sombra para destaque
                flexDir={"column"}
                gap={3}
              >
                <Box
                  as="div"
                  width="100%"
                  dangerouslySetInnerHTML={{ __html: blog.text }}
                  fontSize="1rem"
                  lineHeight="1.6" // Melhora a legibilidade
                  color="gray.700" // Define uma cor de texto suave
                  sx={{
                    iframe: {
                      display: "block",
                      width: "100%",
                      height: "auto",
                      aspectRatio: "16/9", // Mantém a proporção do vídeo
                      maxWidth: "800px", // Define um tamanho máximo para telas grandes
                      margin: "0 auto", // Centraliza o vídeo
                    },
                  }}
                />
                <Divider borderColor="blue.200" />
                <Flex
                  p={4}
                  bg="yellow.50"
                  borderRadius="md"
                  boxShadow="md"
                  alignItems={"center"}
                  gap={5}
                  w={"100%"}
                  flexDir={["column", "row"]}
                >
                  <Image
                    borderRadius={"100%"}
                    w={"10rem"}
                    h={"10rem"}
                    boxShadow={"lg"}
                    bgColor={"white"}
                    objectFit={"cover"}
                    src={`https://amni-prod.s3.amazonaws.com/user_images/${author.photo}`}
                    onError={(e) => {
                      e.target.src = "/assets/defUser.webp"; // Substitui a imagem por um placeholder se houver erro
                    }}
                  />
                  <Flex flexDir={"column"} gap={[2, 3]}>
                    <Text fontWeight="bold">
                      {`${author.name.toUpperCase()} ${author.surname.toUpperCase()}`}
                    </Text>
                    {author?.chairNumber && (
                      <Text fontSize={"0.85rem"} fontWeight={700}>
                        {author.type.includes(
                          process.env.REACT_APP_FELLOWMEMBER
                        )
                          ? "Cadeira: "
                          : "Correspondente: C-"}
                        {author.chairNumber}
                      </Text>
                    )}
                    {author?.patron && (
                      <Text fontSize={"0.85rem"}>
                        {author.type.includes(
                          process.env.REACT_APP_FELLOWMEMBER
                        )
                          ? "Patrono"
                          : "Homenageado"}
                        : {author.patron}
                      </Text>
                    )}

                    <Text fontSize={"0.7rem"} fontStyle={"italic"}>
                      {format(new Date(blog.createdAt), "dd/MM/yyyy HH:mm")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              {auth?.user?.id ? (
                <Comments
                  route={"blogs"}
                  blogId={blogId}
                  reload={reload}
                  setReload={setReload}
                  comments={blog?.comments || []}
                />
              ) : (
                <LogToComment />
              )}
            </>
          )
        )}
      </Content>
    </Layout>
  );
};

export default ReadBlog;
