import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
  useToast,
  Link,
} from "@chakra-ui/react";
import client from "../../utils/client";
import { useState } from "react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const AdvertiserCard = ({ advertiser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const split = process.env.REACT_APP_S3_ADVERTISERSIMAGES.split("/");

  // Função para excluir um anunciante
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await client.delete(`/advertisers/${advertiser.advertiserId}`);
      toast({
        title: "Anunciante excluído",
        description: `O anunciante ${advertiser.advertiserName} foi excluído com sucesso.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Atualize a lista de anunciantes ou redirecione se necessário
      navigate("/gerenciamento_anunciantes");
    } catch (error) {
      console.error("Erro ao excluir anunciante:", error);
      toast({
        title: "Erro ao excluir anunciante.",
        description: "Houve um problema ao tentar excluir o anunciante.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Função para editar um anunciante
  const handleEdit = () => {
    navigate(`/editar_anunciante/${advertiser.advertiserId}`);
  };

  return (
    <>
      {/* Modal para mostrar detalhes do anunciante */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Anunciante</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={"column"} gap={1.5}>
              <Flex gap={2}>
                <Text fontWeight={700}>Nome: </Text>
                <Text>{advertiser?.advertiserName}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Tipo: </Text>
                <Text>{advertiser?.advertiserType.toUpperCase()}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Data de Expiração: </Text>
                <Text>
                  {format(
                    new Date(advertiser?.expirationDate + "T00:00:00"),
                    "dd/MM/yyyy"
                  )}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>URL de Destino: </Text>
                <Link href={advertiser?.targetUrl} isExternal>
                  {advertiser?.targetUrl}
                </Link>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                <Image
                  src={
                    advertiser?.advertiserImage
                      ? `https://${split[0]}.s3.amazonaws.com/${split[1]}/${advertiser.advertiserImage}`
                      : "/assets/defaultAdvertiserImage.png"
                  }
                  w={"100%"}
                  objectFit={"cover"}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Linha da tabela exibindo informações do anunciante */}
      <Tr>
        <Td>{advertiser?.advertiserName}</Td>
        <Td>{advertiser?.advertiserType.toUpperCase()}</Td>
        <Td>
          {format(
            new Date(advertiser?.expirationDate + "T00:00:00"),
            "dd/MM/yyyy"
          )}
        </Td>
        <Td>
          <Link href={advertiser?.targetUrl} isExternal>
            {advertiser?.targetUrl}
          </Link>
        </Td>
        <Td>
          <Flex gap={3}>
            <Tooltip label={"Detalhar anunciante " + advertiser.advertiserName}>
              <IconButton
                size={"sm"}
                colorScheme="blue"
                aria-label="Detalhes"
                icon={<SearchIcon />}
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label={"Editar anunciante " + advertiser.advertiserName}>
              <IconButton
                size={"sm"}
                colorScheme="yellow"
                aria-label="Editar Anunciante"
                icon={<EditIcon />}
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip label={"Excluir anunciante " + advertiser.advertiserName}>
              <IconButton
                size={"sm"}
                colorScheme="red"
                aria-label="Excluir Anunciante"
                icon={<DeleteIcon />}
                onClick={handleDelete}
                isLoading={isLoading}
              />
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default AdvertiserCard;
