import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import client from "../utils/client";
//import client from "../utils/client";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { register: registerA, handleSubmit: handleSubmitA } = useForm();
  const { register: registerB, handleSubmit: handleSubmitB } = useForm();

  const handleSubmitLogin = async (data) => {
    setIsLoading(true);
    try {
      await auth.signin(data, () => navigate("/", { replace: true }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitPassword = async (data) => {
    setIsLoading(true);
    try {
      await client.post(`/users/recover`, data).then(() => setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Content>
        <Flex flexDir={"column"} gap={[2]} alignItems={"center"}>
          <Heading
            px={[2, 6]}
            pt={[2]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            Login
          </Heading>
          <FormControl
            border={"1px solid #ccc"}
            borderRadius={"8px"}
            p={[2, 6]}
            bgColor={"white"}
            isRequired
            w={["95%", "80%", "80%", "40%"]}
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <form id="a" onSubmit={handleSubmitA(handleSubmitLogin)}>
              <Flex mt={5} gap={5} flexDir={"column"} alignItems={"center"}>
                <Box w={"100%"}>
                  <FormLabel htmlFor="email">E-mail</FormLabel>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    {...registerA("email")}
                  />
                </Box>
                <Box w={"100%"}>
                  <FormLabel htmlFor="password">Senha </FormLabel>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    {...registerA("password")}
                  />
                </Box>

                <Button
                  w={"40%"}
                  isLoading={isLoading}
                  type="submit"
                  colorScheme="blue"
                  variant="outline"
                >
                  Logar
                </Button>
              </Flex>
            </form>
            <form id="b" onSubmit={handleSubmitB(handleSubmitPassword)}>
              <Flex
                w={"100%"}
                mt={5}
                gap={2}
                flexDir={"column"}
                alignItems={"center"}
              >
                <FormLabel alignSelf={"flex-start"} htmlFor="email">
                  Esqueceu sua senha?
                </FormLabel>
                <Input
                  id="emailRecover"
                  name="emailRecover"
                  type="email"
                  {...registerB("email")}
                  placeholder="Email cadastrado"
                />
                <Button
                  w={"40%"}
                  isLoading={isLoading}
                  type="submit"
                  colorScheme="blue"
                  variant="outline"
                >
                  Enviar
                </Button>
              </Flex>
            </form>
            <Flex
              w={"100%"}
              mt={5}
              gap={2}
              flexDir={"column"}
              alignItems={"center"}
            >
              <FormLabel alignSelf={"flex-start"} htmlFor="email">
                Ainda não tem cadastro?
              </FormLabel>
              <Link to={"/cadastro"}>
                <Button isLoading={isLoading} type="submit" colorScheme="blue">
                  Cadastrar
                </Button>
              </Link>
            </Flex>
          </FormControl>
        </Flex>
      </Content>
    </Layout>
  );
};
export default Login;
