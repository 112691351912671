import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Content from "../layout/Content";
import { useEffect, useState } from "react";
import client from "../utils/client";
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import { format } from "date-fns";
import { useAuth } from "../context/AuthContext";
import Comments from "../components/Comments";
import LogToComment from "../components/LogToComment";

const DetailNews = () => {
  const { newsId } = useParams();
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const request = async () => {
      try {
        const response = await client.get(`/news/${newsId}`);
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    request();
  }, [newsId, reload]);

  // Determina o padding com base no tamanho da tela
  const paddingX = useBreakpointValue({ base: 4, md: 10 });

  return (
    <Layout>
      <Content>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="50vh">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          !!news && (
            <>
              <Heading
                px={paddingX}
                pt={4}
                variant="outline"
                colorScheme="yellow"
                textDecor="underline"
                alignSelf="flex-start"
                textDecorationColor="#01acec"
                fontSize={["1.5rem", "2rem"]} // Ajuste de tamanho de fonte para responsividade
              >
                {news.title}
              </Heading>
              <Text
                mx={paddingX}
                fontSize="0.85rem"
                fontStyle="italic"
                color="gray.600"
              >
                Pelo Ir∴ {news.authorName},{" "}
                {format(new Date(news.createdAt), "dd/MM/yyyy HH:mm")}
              </Text>
              <Flex w="100%" my={5}>
                <Carousel
                  items={news.images}
                  routeImage="news_images"
                  justImage={true}
                />
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                width="80%"
                maxWidth="800px"
                margin="auto"
                bgColor="#f7f7f7"
                padding="20px"
                borderRadius="8px"
                boxShadow="lg" // Adiciona sombra para destaque
              >
                <Box
                  as="div"
                  width="100%"
                  dangerouslySetInnerHTML={{ __html: news.text }}
                  fontSize="1rem"
                  lineHeight="1.6" // Melhora a legibilidade
                  color="gray.700" // Define uma cor de texto suave
                />
              </Flex>
              {auth?.user?.id ? (
                <Comments
                  route={"news"}
                  blogId={newsId}
                  reload={reload}
                  setReload={setReload}
                  comments={news?.comments || []}
                />
              ) : (
                <LogToComment />
              )}
            </>
          )
        )}
      </Content>
    </Layout>
  );
};

export default DetailNews;
