import { Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import client from "../../utils/client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

// Função para comparar a data de expiração com a data atual, sem considerar timezone
const isDateExpired = (expirationDate) => {
  const today = new Date();
  const [year, month, day] = expirationDate.split("-").map(Number); // Parse da data de expiração
  const expiration = new Date(year, month - 1, day); // Construir a data da expiração no formato UTC

  // Comparar apenas as partes de ano, mês e dia
  return (
    expiration <
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
};

const AdsDesktop = ({ type, w }) => {
  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/advertisers/type/${type}`);

      if (response.data) {
        // Filtrar os anúncios que ainda estão dentro da data de validade
        const validAdvertisers = response.data.filter(
          (ad) => !isDateExpired(ad.expirationDate)
        );
        setAdvertisers(validAdvertisers);
      }
    };
    request();
  }, [type]);

  return (
    <Flex
      display={["none", "none", "flex"]}
      p={2}
      w={w}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {!!advertisers.length && (
        <Swiper
          style={{ width: "100%", height: "auto" }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
        >
          {advertisers.map((el) => (
            <SwiperSlide key={el.advertiserId}>
              <a href={el.targetUrl} target="_blank" rel="noopener noreferrer">
                <Flex
                  position="relative"
                  mx="auto"
                  w="100%"
                  justifyContent={"center"}
                >
                  <Image
                    borderRadius="1rem"
                    src={`https://amni-prod.s3.amazonaws.com/advertisers_images/${el.advertiserImage}`}
                    w={w}
                    h={type === "principal" ? "40vh" : "25vh"}
                    objectFit="contain"
                  />
                </Flex>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Flex>
  );
};

export default AdsDesktop;
