import { Flex, Heading, Spinner } from "@chakra-ui/react";
import Layout from "../layout/Layout";
import Content from "../layout/Content";
import RegisterForm from "../components/RegisterForm";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import client from "../utils/client";

const Register = () => {
  const auth = useAuth();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    auth?.user?.id && setLoading(true);
    const request = async () => {
      const response = await client.get(`/users/${auth?.user?.id}`);
      response.data && setUser(response.data);
      setLoading(false);
    };
    auth?.user?.id && request();
  }, [auth]);

  return (
    <Layout>
      <Content>
        <Flex flexDir={"column"} gap={[2]} alignItems={"center"} w={"100%"}>
          <Heading
            px={[2, 6]}
            pt={[2]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            {auth?.user ? "Meu Perfil" : "Cadastro"}
          </Heading>
          {!loading ? <RegisterForm user={user && user} /> : <Spinner />}
        </Flex>
      </Content>
    </Layout>
  );
};

export default Register;
