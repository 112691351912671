import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import client from "../utils/client";
import { v4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { myBucket } from "../utils/bucket";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DeleteIcon } from "@chakra-ui/icons";
import NotAuthorized from "./NotAuthorized";

const AddNews = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { newsId } = useParams();
  const [textarea, setTextarea] = useState("");
  const auth = useAuth();
  const toast = useToast();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingNews, setLoadingNews] = useState(true);
  const [removedImages, setRemovedImages] = useState([]); // Array para rastrear imagens removidas
  const navigate = useNavigate();

  useEffect(() => {
    if (newsId) {
      const fetchNews = async () => {
        try {
          const response = await client.get(`/news/${newsId}`);
          const newsData = response.data;
          setValue("title", newsData.title);
          setTextarea(newsData.text);
          setImages(
            newsData.images.map((img) => ({
              preview: `https://amni-prod.s3.amazonaws.com/news_images/${img}`,
            }))
          );
        } catch (error) {
          console.error("Error fetching news:", error);
          toast({
            title: "Erro ao carregar a notícia para edição",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } finally {
          setLoadingNews(false);
        }
      };
      fetchNews();
    } else {
      setLoadingNews(false);
    }
  }, [newsId, setValue, toast]);

  const uploadFile = async (file, photoId) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_S3_NEWSIMAGES,
      Key: photoId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setImages([...images, ...newImage]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const onDelete = (preview) => {
    const updatedImages = images.filter((image) => image.preview !== preview);

    // Adicionar ao array de imagens removidas se a imagem já estiver no bucket
    const removedImage = images.find((image) => image.preview === preview);
    if (!removedImage.preview.startsWith("blob:")) {
      setRemovedImages([
        ...removedImages,
        removedImage.preview.split("/").pop(),
      ]);
    }

    setImages(updatedImages); // Atualiza o estado de imagens, removendo a imagem selecionada
  };

  const deleteImageFromBucket = async (imageId) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_NEWSIMAGES,
      Key: imageId,
    };

    myBucket.deleteObject(params, (err, data) => {
      if (err) {
        console.log(`Erro ao excluir a imagem ${imageId} do bucket`, err);
      }
    });
  };

  const handleChange = (value) => {
    setTextarea(value);
  };

  const onSubmit = async (el) => {
    setIsLoading(true);
    try {
      let imagesUrls = [];

      // Processar imagens da interface
      for (const img of images) {
        if (img.preview.startsWith("blob:")) {
          // Novas imagens
          const imageId = `newsPhoto-${v4()}.jpeg`;
          await uploadFile(img, imageId);
          imagesUrls.push(imageId);
        } else {
          // Imagens existentes
          imagesUrls.push(img.preview.split("/").pop());
        }
      }

      // Excluir imagens removidas do bucket
      for (const imageId of removedImages) {
        console.log(`Excluindo imagem ${imageId} do bucket`);
        await deleteImageFromBucket(imageId);
      }

      console.log("Payload de imagens antes de enviar:", imagesUrls);

      const payload = {
        title: el.title,
        authorId: auth.user.id,
        text: textarea,
        images: imagesUrls, // Apenas as imagens restantes após remoção
      };

      if (newsId) {
        // Atualiza notícia existente
        await client.patch(`/news/${newsId}`, payload);
        toast({
          title: "Notícia atualizada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        // Cria nova notícia
        await client.post("/news", payload);
        toast({
          title: "Notícia criada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      navigate("/gerenciamento_noticias");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  if (loadingNews) {
    return (
      <Flex justifyContent="center" alignItems="center" height="50vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      {auth?.user.type.includes(process.env.REACT_APP_ADMINISTRATOR) ||
      auth?.user.type.includes(process.env.REACT_APP_COLABORATOR) ? (
        <Layout>
          <Content>
            <Heading
              px={[2, 6]}
              pt={[2]}
              variant={"outline"}
              colorScheme="yellow"
              textDecor={"underline"}
              alignSelf={"flex-start"}
              textDecorationColor={"#01acec"}
            >
              {newsId ? "Editar Notícia" : "Adicionar Notícias"}
            </Heading>
            <Flex mt={5} alignItems={"center"} flexDir={"column"}>
              <Box w={"100%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex
                    gap={5}
                    w={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Input
                      {...register("title")}
                      placeholder="Título da Notícia"
                    />

                    <ReactQuill
                      theme="snow"
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          ["link"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                      ]}
                      value={textarea}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        fontFamily: "Gleego",
                      }}
                    />

                    <div
                      {...getRootProps()}
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <Flex
                        justifyContent={"center"}
                        bgColor={"#ccc"}
                        p={5}
                        borderRadius={8}
                        minW={"50%"}
                      >
                        <input {...getInputProps()} />
                        <p>
                          {isDragActive
                            ? "Solte as imagens aqui..."
                            : "Arraste e solte imagens aqui ou clique para selecionar"}
                        </p>
                      </Flex>
                    </div>

                    <Flex gap={3} wrap={"wrap"}>
                      {images.map((file) => (
                        <Flex
                          key={file.preview}
                          flexDir={"column-reverse"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={1}
                          my={3}
                        >
                          <IconButton
                            shadow={"dark-lg"}
                            colorScheme="red"
                            aria-label="Remover imagem"
                            size="sm"
                            onClick={() => onDelete(file.preview)}
                            icon={<DeleteIcon />}
                          />
                          <Image
                            shadow={"dark-lg"}
                            borderRadius={8}
                            src={file.preview}
                            alt={file.name}
                            maxW={"100%"}
                            maxH={"200px"}
                          />
                        </Flex>
                      ))}
                    </Flex>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      {newsId ? "Atualizar Notícia" : "Adicionar Notícia"}
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Flex>
          </Content>
        </Layout>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default AddNews;
